@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  textarea::selection {
    @apply bg-gray-200 text-black;
  }
  
  textarea::-moz-selection {
    @apply bg-gray-200 text-black;
  }
}


@font-face {
    font-family: "AeonikBold";
    src: url("../public/Fonts/Aeonik-Bold.ttf");
  }
  
  
  @font-face {
    font-family: "AeonikMedium";
    src: url("../public/Fonts/Aeonik-Medium.ttf");
  }
  
  @font-face {
    font-family: "AeonikRegular";
    src: url("../public/Fonts/Aeonik-Regular.ttf");
  }

  body, html {
      min-width: 320px;
  }

  body>*:not(i,svg){
      font-family: "AeonikRegular", sans-serif;
  }

  body::-webkit-scrollbar,
.cusscroller::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-track,
.cusscroller::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb,
.cusscroller::-webkit-scrollbar-thumb{
  background-color: rgb(219, 219, 219);
  border-radius: 20px;
}

body p {
  margin:0px;
}
